import { Injectable } from '@angular/core';
import { where } from '@angular/fire/firestore';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { Club } from '@models/clubs';
import { COLLECTIONS } from '@models/collections';
import {
  InscriptionPlayersPayment,
  InscriptionTeamsPayment,
  Movement,
  PaymentInscriptionResponse,
  PaymentLicenseResponse,
} from '@models/inscription';
import { GenerateLinkPayload, GenerateLinkResponse } from '@models/invoice';
import { LicensePayment } from '@models/license';
import { MovementType } from '@models/masters';
import { Observable, combineLatest, map, shareReplay, switchMap } from 'rxjs';
import { ClubsService } from './club.service';
import { FirestoreService } from './firestore.service';
import { SeasonManagerService } from './season-manager.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  private COLLECTION = COLLECTIONS.MOVEMENTS;

  public all$ = this.seasonManager.season$.pipe(
    switchMap((season) => this.firestore.listDocuments<Movement>(this.COLLECTION, where('seasonYear', '==', season))),
    shareReplay(1),
  );

  public incomeMovements$: Observable<(Movement & { club?: Club })[]> = combineLatest([
    this.all$.pipe(map((movements) => movements.filter((m) => m.movementType === 'INCOME'))),
    this.clubsService.all$,
  ]).pipe(
    map(([movements, clubs]) => {
      const sortedMovements = movements.sort((a, b) => {
        const dateA = a.date ? new Date(a.date) : new Date(a.createdAt || 0);
        const dateB = b.date ? new Date(b.date) : new Date(b.createdAt || 0);
        return dateB.getTime() - dateA.getTime(); // Orden descendente
      });

      return sortedMovements.map((movement) => ({
        ...movement,
        club: movement.clubId ? clubs.find((club) => club.id === movement.clubId) : undefined,
      }));
    }),
    shareReplay(1),
  );

  public clubBalance$: Observable<(Club & { ingresos?: number; gastos?: number })[]> = combineLatest([
    this.clubsService.all$,
    this.all$.pipe(map((movements) => movements.filter((m) => m.movementType === MovementType.INCOME))),
    this.all$.pipe(map((movements) => movements.filter((m) => m.movementType === MovementType.EXPENDITURE))),
  ]).pipe(
    map(([clubs, incomeMovements, expenditureMovements]) => {
      return clubs.map((club) => {
        const ingresos = incomeMovements
          .filter((movement) => movement.clubId === club.id)
          .reduce((sum, movement) => sum + +movement.amount, 0);
        const gastos = expenditureMovements
          .filter((movement) => movement.clubId === club.id)
          .reduce((sum, movement) => sum + +movement.amount, 0);
        return {
          ...club,
          ingresos,
          gastos,
        };
      });
    }),
    shareReplay(1),
  );

  constructor(
    private functions: Functions,
    private firestore: FirestoreService,
    private clubsService: ClubsService,
    private seasonManager: SeasonManagerService,
  ) {}

  inscriptionTeamsPayment(data: InscriptionTeamsPayment) {
    const callable = httpsCallable<InscriptionTeamsPayment, PaymentInscriptionResponse>(
      this.functions,
      'inscriptionTeamsPayment',
    );
    return callable(data);
  }

  inscriptionPlayersPayment(data: InscriptionPlayersPayment) {
    const callable = httpsCallable<InscriptionPlayersPayment, PaymentInscriptionResponse>(
      this.functions,
      'inscriptionPlayersPayment',
    );
    return callable(data);
  }

  payLicense(data: LicensePayment) {
    const callable = httpsCallable<LicensePayment, PaymentLicenseResponse>(this.functions, 'payLicense');
    return callable(data);
  }

  getByClub(clubId: string) {
    return this.all$.pipe(map((movements) => movements.filter((movement) => movement.clubId === clubId)));
  }

  create(movement: Omit<Movement, 'id'>) {
    return this.firestore.create(this.COLLECTION, movement);
  }

  update(id: string, movement: Partial<Movement>) {
    return this.firestore.update(this.COLLECTION, {
      id,
      ...movement,
    });
  }

  createOrUpdate(id: string | undefined, user: Omit<Movement, 'id'>) {
    return id ? this.update(id, user) : this.create(user);
  }

  get(id: string) {
    return this.firestore.getDocument<Movement>(this.COLLECTION, id);
  }

  delete(id: string) {
    return this.firestore.delete(COLLECTIONS.MOVEMENTS, id);
  }

  deleteForever(id: string) {
    return this.firestore.deleteForever(COLLECTIONS.MOVEMENTS, id);
  }

  generateInvoice(movementsIds: string[]) {
    const callable = httpsCallable<string[], boolean>(this.functions, 'generateInvoice');
    return callable(movementsIds);
  }

  createPaymentLink(linkPayload: GenerateLinkPayload) {
    const callable = httpsCallable<GenerateLinkPayload, GenerateLinkResponse>(this.functions, 'generatePaymentLink');
    return callable(linkPayload);
  }
}
